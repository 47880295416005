<template>
    <div class="mx-auto clickable-pointer">
        <div v-if="!category_data.view_more">
            <div class="d-flex-column" @click="redirectToCategory">
                <div class="mx-auto">
                    <div v-if="category_type == 'Teleconsultation'">
                        <div class="pa-4 rounded-lg d-flex justify-center align-center specialization-subcomponent category-background">
                            <img class="" style="width: 60%; height: 60%;" :src="'https://s3iconimages.mymedicine.com.mm/' + (category_data.image_filename ? category_data.image_filename : 'catagory_fallback_image_small.svg')"  alt="category-icon">
                        </div>
                    </div>
                    <div v-else>
                        <div class="rounded-lg d-flex justify-center align-center specialization-subcomponent">
                            <img class="category-image rounded-lg" :src="'https://s3iconimages.mymedicine.com.mm/' + (category_data.image_filename ? category_data.image_filename : 'catagory_fallback_image_small.svg')"  alt="category-icon">
                        </div>
                    </div>
                </div>
                <div>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <p class="ma-0 pt-4 body-text font-weight-medium text-capitalize line-clamp"
                                v-bind="attrs"
                                v-on="on"
                                v-if="$store.state.locale == 'en'"
                            >{{ category_data.name }}</p>
                            <p class="ma-0 pt-4 body-text font-weight-medium text-capitalize line-clamp"
                                v-bind="attrs"
                                v-on="on"
                                v-else
                            >{{ category_data.name_mm }}</p>
                        </template>
                        <span class="text-capitalize" v-if="$store.state.locale == 'en'">{{ category_data.name }}</span>
                        <span class="text-capitalize" v-else>{{ category_data.name_mm }}</span>
                    </v-tooltip>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="d-flex-column" @click="viewMore()">
                <div class="mx-auto">
                    <div class="pa-4 rounded-lg mx-auto specialization-subcomponent category-background d-flex justify-center align-center" :class="[category_type == 'Teleconsultation' ? '' : 'category-image']">
                        <img class="" style="width: 60%; height: 60%;" :src="'https://s3iconimages.mymedicine.com.mm/view_more.svg'"  alt="Category Name">
                    </div>
                </div>

                <div>
                    <p class="ma-0 pt-4 body-text font-weight-medium d-flex justify-center align-center">{{ $t("Customer.Home.View_More_Specialities") }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'categorySubComponent',
    props: {
        category_data: {
            type: Object,
            requied: true
        },
        category_type: {
            type: String,
            required: false
        }
    },
    mounted() {
        if(this.$store.state.locale != "") {
            this.$i18n.locale =  this.$store.state.locale;
        } else {
            this.$i18n.locale = 'mm';
            this.$store.dispatch("changeLocale", this.$i18n.locale);
        }
    },
    methods: {
        viewMore() {
            this.$emit('redirectOnViewMore');
        },
        redirectToCategory() {
            this.$emit('redirectToParticularCategory', this.category_data._id, this.category_data.name);
        }
    }
}
</script>

<style>
.normaltext{
    font-size: 12px;
}

.line-clamp {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>

<style lang="scss" scoped>
.category-background {
    background-color: #F4F6FA;
}

.specialization-subcomponent {
    aspect-ratio: 1;
}

.category-image {
    object-fit: contain;
    
    @media (max-width: 600px) {
        width: 100%;
        height: 100%;
    }

    @media (min-width: 601px) and (max-width: 900px) {
        width: 120px;
        height: 120px;
    }

    @media (min-width: 901px) and (max-width: 1264px) {
        width: 120px;
        height: 120px;
    }

    @media (min-width: 1265px) {
        width: 120px;
        height: 120px;
    }
}
</style>